import React, { Component } from 'react'
import '../../css//Bottom.css';

var $ = document;
class Bottom extends Component {

    componentDidMount() {
        this.onScrollBlur();
        window.addEventListener("scroll", this.onScrollBlur);
        window.addEventListener("resize", this.onScreenResize);
        this.onScreenResize();
    }
    constructor() {
        super()
        this.state = {
            bar_height: 0,
            window_width: window.innerWidth,
            window_height: window.innerHeight
        }
    }

    onScrollBlur = () => {
        const scrollTop = $.documentElement.scrollTop;
        const barHeight = $.getElementById("Bottom_bone").clientHeight;
        const rootHeight = $.getElementById("root").clientHeight;
        
        // if (this.state.window_height > rootHeight) {
        //     $.getElementById("Bottom_bone").style = "position: absolute; bottom: 0; background: #595959; width: inherit;";
        //     console.log("window_height ? " +  this.state.window_height +  " ,rootHeight ? " +  rootHeight + " ,barHeight ? " +  barHeight );
        // }
        // else {
        //     $.getElementById("Bottom_bone").style = ""
        //     console.log("root height with in ");
        // }
    }

    onScreenResize = () => {
        const barHeight = $.getElementById("Bottom_bone").clientHeight;
        const rootHeight = $.getElementById("root").clientHeight;
        
        // if (window.innerHeight > rootHeight) {
            // $.getElementById("Bottom_bone").style = "position: fixed; bottom: 0px;  width: inherit;";
            // console.log("window_height ? " +  window.innerHeight +  " ,rootHeight ? " +  rootHeight + " ,barHeight ? " +  barHeight );
        // }
        // else {
        //     $.getElementById("Bottom_bone").style = ""
        //     console.log("root height with in ");
        // }
    }

    render() {
        return (
            <div id="Bottom_bone" className={this.props.css !== undefined ? this.props.css: ""}>
                <div id="Bottom_body">
                    {/* <div id="Bottom_columes">
                        <a href='./about' className='columes_head'>關於我們</a>
                        <div id="col_seperater"></div>
                        <a href='./about' >公司簡介</a>
                        <a href='./' >企業理念</a>
                    </div>
                    <div id="Bottom_columes">
                        <a href='./products_pv' className='columes_head'>產品介紹</a>
                        <div id="col_seperater"></div>
                        <a href='./brands' >代理品牌</a>
                        <a href='./partners' >合作公司</a>
                    </div>
                    <div id="Bottom_columes">
                        <a href='./contact' className='columes_head'>聯絡我們</a>
                        <div id="col_seperater"></div>
                        <a href='./contact' >公司資訊</a>
                    </div> */}
                    <div id="Bottom_columes" className='just_text'>
                        Copyright © 2024 Little Joy Limited
                    </div>
                </div>
            </div >
        )
    }
}


export default Bottom;