import React, { Component } from 'react'
import Main from './Main.js';
import Privacy from './Privacy.js';
// import About from './About.js';
import DeleteAcc from './DeleteAcc.js';
// import ProductsPV from './Products_pv.js';
// import Products from './Products.js';
// import Partner from './Partner.js';
// import Contact from './Contact.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";

var $ = document;
class App extends Component {
    componentDidMount() {
        window.addEventListener("resize", this.onScreenResize);
        this.onScreenResize();
    }

    onScreenResize = () => {
        const barHeight = $.getElementById("Bottom_bone").clientHeight;
        const rootHeight = $.getElementById("root").clientHeight;
        
        $.getElementById("root").style = "width:100%; height:"+ window.innerHeight +"px;"; 

        console.log("window_height ? " +  window.innerHeight +  " ,rootHeight ? " +  rootHeight );
        if (window.innerHeight > rootHeight) {
            $.getElementById("body_force").style = "width:100%; height:"+ window.innerHeight +"px;";
            // console.log("window_height ? " +  window.innerHeight +  " ,rootHeight ? " +  rootHeight );
        }
        else {
            $.getElementById("Bottom_bone").style = ""
            console.log("root height with in ");
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/delete-account" element={<DeleteAcc />} />
                    {/* <Route path="/about" element={<About />} />
                    <Route path="/products_pv" element={<ProductsPV />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/partners" element={<Partner />} />
                    <Route path="/contact" element={<Contact />} /> */}
                </Routes>
            </BrowserRouter>


        )
    }
}

export default App