import React, { Component } from 'react'
import '../css/Privacy.css';
import { obj } from "../components/language.js"
import Bottom from '../components/parts/Bottom.js';

var $ = document;
class Privacy extends Component {
    
    constructor() {
        super()
        this.state = {
            language: require('./language/zh.json'),
            layoutState: 2,

        }
    }

    language = (e) => {
        this.setState({ language: require("./language" + obj[e]) });
        this.setState({ layoutState: e })
    }

    componentDidMount() {
        console.log("did mount");
        window.addEventListener("resize", this.onScreenResize);
        this.onScreenResize();
    }

    onScreenResize = () => {
        const barHeight = $.getElementById("Bottom_bone").clientHeight;
        const rootHeight = $.getElementById("root").clientHeight;
        
        // $.getElementById("root").style = "width:100%; height:"+ window.innerHeight +"px;"; 

        console.log("window_height ? " +  window.innerHeight +  " ,rootHeight ? " +  rootHeight );
        $.getElementById("Privacy_main").style = "width:100%; height:"+ window.innerHeight +"px;";
    }

    render() {
        const language = this.state.language;
        const layoutState = this.state.layoutState;
        const languageTap = this.languageTap;
        var style_1;
        if (layoutState === 0) {
            style_1 = {
                wordBreak: "break-all"
            }
        }

        return (

            <div id="Privacy_main">
                {/* <Tapbar languageTap={languageTap} language={language} layoutState={layoutState} />
                <Banner page_name={language.partner_title} imgSrc={bannerImg} /> */}
                <div id="Privacy_bone">
                    <div id="Privacy_body">
                        <div id="Privacy_text" dangerouslySetInnerHTML={{ __html: language.privacy }} style={style_1} />
                    </div>
                </div >
                <Bottom />
            </div>
        )
    }
}



export default Privacy;