import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './page/App.js';
import './css/media/media_main.css';
import './css/media/media_tapbar.css';
import './css/media/media_banner.css';
import './css/media/media_about.css';
import './css/media/media_carousel.css';
import './css/media/media_team.css';
import './css/media/media_partner.css';
import './css/media/media_bottom.css';
ReactDOM.render(
    <>
        <App />
    </>,
    document.getElementById('root')
);