import React, { Component } from 'react'
import '../css/Main.css';
import { obj } from "../components/language.js"
import Bottom from '../components/parts/Bottom.js';
// import Tapbar from '../components/parts/Tapbar.js';
// import Banner from '../components/parts/Banner.js';
// import PagingView from '../components/parts/PagingView.js';


class Main extends Component {
    
    constructor() {
        super()
        this.state = {
            language: require('./language/zh.json'),
            layoutState: 2,

        }
    }

    language = (e) => {
        this.setState({ language: require("./language" + obj[e]) });
        this.setState({ layoutState: e })
    }

    produstsShort(){
        var imgResFolderPath = "";
        var pList = {"name":"", "img":""}

        // ToDo : dynamic product list
            // looking for main page product list api
            // data sturcturing : [{name, image, link-path},..]
    };

    render() {
        const language = this.state.language;
        const layoutState = this.state.layoutState;
        const languageTap = this.languageTap;

        const handleItemOnClick = (url) =>{
            // page route
            const win = window.open(url, '_blank' );
            if (win != null) {
                win.focus();
            }
        }

        // const renderProdItems = ()=>{
        //     const itemNames = language.products_itemTypeNames;
        //     let items = []; 
        //     for(let i = 0;i<itemNames.length;i++){
        //         items.push(
        //             <div className="_list_item" onClick={()=>handleProdItemOnClick(i)} key={i}> <div className="_list_item_img"> </div> <div className="_list_item_name">{itemNames[i]}</div> </div>
        //         );
        //     }
        //     return items;
        // }

        // const handleProdItemOnClick = (e) =>{
        //     // page route
        //     const tapLinkArray = [];
        //     let url = tapLinkArray[e];
        //     if(url == undefined){
        //         url = "/products";
        //     }
        //     const win = window.open(url, '_self' );
        //     if (win != null) {
        //         win.focus();
        //     }
        // }

        return ( <div id="main">
                        {/* <Tapbar languageTap={languageTap} language={language} layoutState={layoutState} />
                        <Banner /> <Banner page_name="安耀科技有限公司" /> */}
                        <div id="content_bone">
                            <div id="content_company_details">
                                {/* <div id="_p">
                                    <h1>{language.about_text_header} <div className="cont_seperating"/></h1>
                                    <div id="_text" dangerouslySetInnerHTML={{ __html: language.about_text }}  />
                                </div> */}
                                <div id="com_logo"></div>
                                <div id="img_text_info"></div>
                                <a   href = "mailto:info@littlejoyltd.com?subject = Project Quoatation">
                                <div  id="btn_contact_email" 
                                // onClick={()=>handleItemOnClick('mailto: info@littlejoyltd.com')} 
                                ></div>
                                </a>
                                
                            </div>
                            {/* <div id="overlay_bone">
                                <div id="content_bound">
                                    <div id="_content">
                                        <h1>{language.about_text2_header}<div className="cont_seperating1"/> </h1>
                                        <div id="subcont" dangerouslySetInnerHTML={{ __html: language.about_text2 }} />
                                    </div>
                                </div>
                            </div> */}
                            {/* <div id="content_prod_short_list">
                                <h1>{language.main_header_prods}<div className="cont_seperating"/></h1>
                                <div id="_list">
                                    {renderProdItems()}
                                </div>
                            </div> */}
                            {/* <div id="content_partner_short_list">
                                <h1>{language.main_header_partners}<div className="cont_seperating"/></h1>
                                {<PagingView />}
                            </div> */}
                        </div> 
                        <Bottom />
                    </div> )
    }
}

export default Main